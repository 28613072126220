import { IStatusCSVExportResponse } from '../api/api-sdk';

export enum TaskStatus {
  pending = 'QUEUED',
  runnings = 'PROCESSING',
  completed = 'DONE',
  failed = 'ERROR',
}

export interface ITask {
  id: string;
  title: string;
  state?: IStatusCSVExportResponse;
}
