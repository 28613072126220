import { Pipe, PipeTransform } from '@angular/core';
import { ColorUtils } from '../../utils/color';

@Pipe({
  name: 'alpha',
  standalone: true,
})
export class AlphaPipe implements PipeTransform {
  transform(color: string, opacity: number) {
    return ColorUtils.addAlpha(color, opacity);
  }
}
