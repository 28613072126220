import dayjs from 'dayjs';
import { AlertType } from './alert';
import { IDevice } from './dashboard';

export enum NodeAttributeType {
  attribute,
  variable,
  cameraPoint,
}

export enum NodeLocation {
  inside = 'inside',
  outside = 'outside',
}

export interface INodeAttribute {
  id: string;
  valuePath: string;
  title: string;
  selected: boolean;
  uom?: string;
  alertMinValue?: number;
  alertMaxValue?: number;
}

export interface INodeAttributesGroup {
  type: NodeAttributeType;
  id: string;
  attributes: INodeAttribute[];
}

export interface INodeAttributesGroupWithAlert extends INodeAttributesGroup {
  attributes: (INodeAttribute & { alertTypes: AlertType[] | undefined })[];
}

export interface INodeAttributeValue extends INodeAttribute {
  value: unknown;
  alertType: AlertType;
  uom?: string;
}

export interface INodeListItem {
  id: number;
  name: string;
  date: dayjs.Dayjs;
  cameraId?: number;
  lastReading?: dayjs.Dayjs;
  attributes: INodeAttributeValue[];
  alerts: AlertType[];
  visible: number;
}

export interface INodeForm {
  name: string;
  visible: boolean;
  location: NodeLocation;
  statusWarningAlerts: boolean;
  offlineAlerts: boolean;
}

export interface IVariableAlert {
  id: string;
  alertTypes: AlertType[];
}

export interface IColorRange {
  less?: number;
  from?: number;
  to?: number;
  greater?: number;
  backgroundColor: string;
  textColor?: string;
}

export interface IVisualMapPoint {
  x: number;
  y: number;
}

export interface IVisualMapData {
  width: number;
  height: number;
  devices: (IVisualMapPoint & { id: number })[];
}

export interface IVisualMapDevice extends IDevice {
  position: IVisualMapPoint;
}
